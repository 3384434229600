<template>
    <div class="footer">
        <div class="foot">
            <div class="left">
                <router-link class="logo" to="/home">
                    <img class="logo" src="../assets/img/logo.png" alt="">
                </router-link>
                <div class="hot">
                    <div class="item">
                        <div class="label">合作热线</div>
                        <div class="text">{{ info.collaboration_hotline }}</div>
                    </div>
                    <div class="item">
                        <div class="label">咨询热线</div>
                        <div class="text">{{ info.collaboration_hotline }}</div>
                    </div>
                </div>
                <div class="address">
                    <div class="label">我们的地址</div>
                    <div class="text">{{ info.our_address }}</div>
                </div>
                <div class="link">
                    <div class="label">相关链接</div>
                    <div class="img_box">
                        <div class="item">
                            <div class="img">
                                <el-image class="image" :src="info.wechat_official_account_pic"></el-image>
                            </div>
                            <div class="text">公众号</div>
                        </div>
                        <div class="item">
                            <div class="img">
                                <el-image class="image" :src="info.wechat_mini_program_pic"></el-image>
                            </div>
                            <div class="text">小程序</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="ul">
                    <div class="label">展览</div>
                    <router-link class="li" to="/exhibition">我要参展</router-link>
                    <router-link class="li" to="/exhibition">展商名录</router-link>
                    <router-link class="li" to="/exhibition">展商指南</router-link>
                    <router-link class="li" to="/exhibition">历届展览</router-link>
                </div>
                <div class="ul">
                    <div class="label">论坛</div>
                    <router-link class="li" to="/forum">往届论坛</router-link>
                    <router-link class="li" to="/forum">报名</router-link>
                </div>
                <div class="ul">
                    <div class="label">行业活动</div>
                    <router-link class="li" to="/game">赛事评选</router-link>
                    <router-link class="li" to="/inspect">行业考察</router-link>
                </div>
                <div class="ul">
                    <div class="label">关于我们</div>
                    <router-link class="li" to="/about">关于我们</router-link>
                    <router-link class="li" to="/contact">联系我们</router-link>
                </div>
            </div>
        </div>
        <div class="bottom">
            <a href="https://beian.miit.gov.cn" target="_blank">{{ info.icp_filing_number }}</a>
        </div>
    </div>
</template>

<script>
import { getConfig } from '@/api/api'
export default {
    name: 'footView',
    data () {
        return {
            info: {}
        }
    },
    mounted () {
        this.getConfig()
    },
    methods: {
        getConfig () {
            getConfig().then(res => {
                this.info = res.data
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.foot {
    padding: 120px 240px 0 240px;
    display: flex;
    background: #fff;
    border-top: 1px solid #DFE2E5;

    .left {
        padding-right: 100px;
        border-right: 1px solid #DFE2E5;

        .logo {
            width: 171px;
            height: 62px;
        }

        .hot {
            display: flex;
            align-items: center;
            margin-top: 40px;

            .item {
                &:first-child {
                    margin-right: 46px;
                }

                .label {
                    font-weight: bold;
                    font-size: 18px;
                    color: #292E33;
                    line-height: 24px;
                }

                .text {
                    font-weight: bold;
                    font-size: 18px;
                    color: #F40009;
                    line-height: 24px;
                    margin-top: 8px;
                }
            }
        }

        .address {
            margin-top: 30px;

            .label {
                font-weight: bold;
                font-size: 18px;
                color: #292E33;
                line-height: 24px;
            }

            .text {
                font-size: 16px;
                color: #43484C;
                line-height: 21px;
                margin-top: 8px;
            }
        }

        .link {
            margin-top: 30px;

            .label {
                font-weight: bold;
                font-size: 18px;
                color: #292E33;
                line-height: 24px;
            }

            .img_box {
                display: flex;
                align-items: center;
                margin-top: 10px;

                .item {
                    &:first-child {
                        margin-right: 10px;
                    }

                    .img {
                        width: 100px;
                        height: 100px;
                        border-radius: 8px;
                        border: 2px solid #0068B7;
                        padding: 4px;

                        .image {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .text {
                        font-size: 16px;
                        color: #43484C;
                        line-height: 21px;
                        margin-top: 6px;
                        text-align: center;
                    }
                }
            }
        }
    }

    .right {
        padding-left: 200px;
        display: flex;

        .ul {
            width: 143px;
            margin-right: 53px;
            display: flex;
            flex-direction: column;

            &:last-child {
                margin-right: 0;
            }

            .label {
                font-weight: bold;
                font-size: 18px;
                color: #292E33;
                line-height: 24px;
            }

            .li {
                font-size: 16px;
                color: #43484C;
                line-height: 21px;
                margin-top: 30px;
                text-decoration: none;

                &:first-child {
                    margin-top: 40px;
                }

            }
        }
    }

}

.bottom {
    padding: 70px 240px 60px 240px;

    a {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
        line-height: 24px;
        text-decoration: none;
    }
}
</style>