<template>
  <div id="app">
    <navbar></navbar>
    <router-view />
    <foot></foot>
  </div>
</template>

<script>
import navbar from './components/navbar.vue'
import foot from './components/foot.vue'
export default {
  name: 'App',
  components: {
    navbar,
    foot,
  }
}
</script>

<style lang="scss">
#app {
  font-family: "Microsoft YaHei", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "微软雅黑", Arial, sans-serif;
  min-width: 800px;
  font-size: 0;
}

html {
  margin: 0;
  padding: 0;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  object-fit: cover !important;
  max-width: 100% !important;
}
</style>
