import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css/normalize.css'
import './utils/rem'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Popup from './utils/popup.js'
import axios from "axios"
Vue.prototype.$popup = Popup.install
import { formatDate } from './utils/time.js'
Vue.prototype.$formatDate = formatDate
Vue.prototype.$axios = axios
Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
