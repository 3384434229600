<template>
    <div>
        <nav class="navbar">
            <router-link class="logo" to="/home">
                <img src="@/assets/img/logo.png" alt="">
            </router-link>
            <div class="right">
                <router-link class="item" to="/home">首页</router-link>
                <router-link class="item" to="/exhibition">展览</router-link>
                <router-link class="item" to="/forum">论坛</router-link>
                <router-link class="item" to="/game">赛事评选</router-link>
                <router-link class="item" to="/inspect">行业考察</router-link>
                <router-link class="item" to="/platform">商机平台</router-link>
                <router-link class="item" to="/about">关于我们</router-link>
                <router-link class="item" to="/contact">联系我们</router-link>
            </div>
        </nav>
        <div class="gap"></div>
    </div>
</template>

<script>
export default {
    name: 'navBar'
}
</script>

<style lang="scss" scoped>
.gap {
    height: 80px;
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 240px;
    height: 80px;
    background: #FFF;
    border-bottom: 1px solid #EBEDF0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;

    .logo {
        img {
            width: 143px;
            height: 52px;
        }
    }

    .right {
        display: flex;
        align-items: center;

        .item {
            margin-right: 60px;
            text-decoration: none;
            color: #43484C;
            font-size: 18px;

            &.router-link-active {
                font-weight: bold;
                color: #0068B7;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
</style>