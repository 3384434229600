import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/index/index.vue'),
  },
  {
    path: '/brand',
    name: 'brand',
    component: () => import('../views/brand/brand.vue')
  },
  {
    path: '/exhibition',
    name: 'exhibition',
    component: () => import('../views/exhibition/exhibition.vue')
  },
  {
    path: '/exhibition/detail',
    name: 'exhibitionDetail',
    component: () => import('../views/exhibition/detail.vue')
  },
  {
    path: '/forum',
    name: 'forum',
    component: () => import('../views/forum/forum.vue')
  },
  {
    path: '/forum/detail',
    name: 'forumDetail',
    component: () => import('../views/forum/detail.vue')
  },
  {
    path: '/game',
    name: 'game',
    component: () => import('../views/game/game.vue')
  },
  {
    path: '/game/detail',
    name: 'gameDetail',
    component: () => import('../views/game/detail.vue')
  },
  {
    path: '/inspect',
    name: 'inspect',
    component: () => import('../views/inspect/inspect.vue')
  },
  {
    path: '/inspect/detail',
    name: 'gameDetail',
    component: () => import('../views/inspect/detail.vue')
  },
  {
    path: '/platform',
    name: 'platform',
    component: () => import('../views/platform/platform.vue')
  },
  {
    path: '/platform/detail',
    name: 'platformDetail',
    component: () => import('../views/platform/detail.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about/about.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/contact/contact.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((err) => err)
}

export default router
