//接口
//引入axios 
import request from '@/utils/request'// 这里是引入咱们的axios用axios来进行异步接口请求


/**
 * 首页banner
 */
export function getBsnner (params) {
    return request({
        url: '/officialwebsite/banner/lst',
        method: 'get',
        params,

    })
}

/**
 * 首页会议活动
 */
export function getExhibition (params) {
    return request({
        url: '/officialwebsite/exhibition/conference/lst',
        method: 'get',
        params,

    })
}
/**
 * 首页统计数据
 */
export function getIndexdata (params) {
    return request({
        url: '/officialwebsite/indexdata',
        method: 'get',
        params,

    })
}
/**
 * 首页厂商/合作媒体/合作品牌
 */
export function getCombination (id, params) {
    return request({
        url: '/officialwebsite/combination/lst/' + id,
        method: 'get',
        params,

    })
}
/**
 * 首页合作嘉宾
 */
export function getGuests (params) {
    return request({
        url: '/officialwebsite/collaborative/guests/lst',
        method: 'get',
        params,

    })
}
/**
 * 首页合作产品
 */
export function getProduct (params) {
    return request({
        url: '/officialwebsite/product/lst',
        method: 'get',
        params,

    })
}
/**
 * 近期/往期展览
 */
export function getPlaceList (params) {
    return request({
        url: '/officialwebsite/place/hotel/list',
        method: 'get',
        params,

    })
}
/**
 * 活动详情
 */
export function getActivityId (params) {
    return request({
        url: '/exhibition/detail',
        method: 'get',
        params,

    })
}
/**
 * 酒店/会馆列表
 */
export function getExhibitionList (params) {
    return request({
        url: '/exhibition/list',
        method: 'get',
        params,

    })
}
/**
 * 近期/往期会议活动
 */
export function getConferenceList (params) {
    return request({
        url: '/officialwebsite/conference/list',
        method: 'get',
        params,

    })
}
/**
 * 会议活动详情
 */
export function getConferenceDetail (params) {
    return request({
        url: '/exhibition/conference/details',
        method: 'get',
        params,

    })
}
/**
 * 赛事评选/科学考察
 */
export function getSportsList (params) {
    return request({
        url: '/officialwebsite/sports/science/list',
        method: 'get',
        params,

    })
}
/**
 * 赛事评选详情/科学考察详情
 */
export function getSportsDetail (id, params) {
    return request({
        url: '/officialwebsite/sports/science/' + id,
        method: 'get',
        params,

    })
}
/**
 * 社区分类
 */
export function getCategoryList (params) {
    return request({
        url: '/community/category/lst',
        method: 'get',
        params,
    })
}
/**
 * 动态列表
 */
export function getCommunityList (params) {
    return request({
        url: '/community/lst',
        method: 'get',
        params,
    })
}
/**
 * 动态详情
 */
export function getCommunitDetail (id, params) {
    return request({
        url: '/community/show/' + id,
        method: 'get',
        params,
    })
}
/**
 * 评论列表
 */
export function getCommunitReply (id, params) {
    return request({
        url: '/community/' + id + '/reply',
        method: 'get',
        params,
    })
}
/**
 * 关于我们
 */
export function getAboutus (params) {
    return request({
        url: '/officialwebsite/aboutus',
        method: 'get',
        params,
    })
}
/**
 * 展商名录
 */
export function getDirectorList (params) {
    return request({
        url: '/officialwebsite/director/list',
        method: 'get',
        params,
    })
}
/**
 * 配套活动
 */
export function getVenueConferenceList (params) {
    return request({
        url: '/exhibition/venue/conference/list',
        method: 'get',
        params,
    })
}
/**
 * 合作商家详情
 */
export function getCombinationDetail (id, params) {
    return request({
        url: '/officialwebsite/combination/' + id,
        method: 'get',
        params,
    })
}
/**
 * 合作热线/二维码
 */
export function getConfig (params) {
    return request({
        url: '/officialwebsite/config',
        method: 'get',
        params,
    })
}