<template>
    <div class="popup" v-if="show" @click.self="closePopup">
        <div class="box">
            <el-image class="close" :src="require('@/assets/img/close.png')" @click="closePopup"></el-image>
            <div class="title">博酒汇小程序二维码</div>
            <div class="img_box">
                <el-image class="img" :src="logo.wechat_mini_program_pic"></el-image>
            </div>
            <div class="tip">扫描识别上方二维码，在小程序可进行更多操作哦~</div>
        </div>
    </div>
</template>

<script>
import { getConfig } from '@/api/api'
export default {
    name: 'app',
    data () {
        return {
            show: true,
            logo: ""
        }
    },
    created () {
        getConfig().then(res => {
            this.logo = res.data
        })
    },
    methods: {
        closePopup () {
            this.show = false
        }
    }
}
</script>

<style lang="scss" scoped>
.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    .box {
        width: 484px;
        border-radius: 12px;
        background: #FFF;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 60px;
        position: relative;

        .close {
            position: absolute;
            width: 34px;
            height: 34px;
            top: 30px;
            cursor: pointer;
            right: 30px;
        }

        .title {
            margin-top: 60px;
            font-weight: bold;
            font-size: 20px;
            color: #0D1319;
            line-height: 28px;
        }

        .img_box {
            width: 180px;
            height: 180px;
            border-radius: 8px;
            border: 2px solid #0068B7;
            margin-top: 30px;
            overflow: hidden;

            .img {
                width: 176px;
                height: 176px;
            }
        }

        .tip {
            margin-top: 30px;
            font-size: 16px;
            color: #5D6166;
            line-height: 24px;
        }

    }
}
</style>
